import React, { useRef } from "react"
import { Link, graphql, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import moment from "moment"

import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import { labels, localeUrls, days } from "../langs/langs"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { lSVG } from "../components/ArrowLink"
import PartnersSlider from "../components/PartnersSlider"
import calendarI from "../images/calendar.svg"

const StoreDetailTemplate = ({
  location,
  data: { post },
  pageContext: { localeID },
}) => {
  const urlTranslations = {
    en_US:
      localeUrls["en_US"]["EVENTS"] +
      "/" +
      (localeID === "en_US"
        ? post.slug
        : !!post.translated[0]?.slug
        ? post.translated[0].slug
        : ""),
    sk_SK:
      localeUrls["sk_SK"]["EVENTS"] +
      "/" +
      (localeID === "sk_SK"
        ? post.slug
        : !!post.translated[0]?.slug
        ? post.translated[0].slug
        : ""),
  }

  return (
    <Layout
      location={location}
      localeID={localeID}
      translatedSlug={urlTranslations}
    >
      <Seo title={post.title} description={post.excerpt} />
      <div className="breadCrumbs">
        <div className="content center">
          <a className="aArow left" onClick={() => navigate(-1)}>
            {lSVG("#000")} {labels[localeID]["BACK"]}
          </a>
          <div className="fillFlex" />
          <Link to={localeUrls[localeID]["EVENTS"]}>
            {labels[localeID]["EVENTS"]}
          </Link>
          <div>/</div>
          <Link to={localeUrls[localeID]["EVENTS"]}>{post.title}</Link>
        </div>
      </div>
      <div className="hPadSec sm">
        <div className="content post">
          <div className="infoBox">
            <p className="center l">
              <img src={calendarI} />
              <span className="oh">
                {moment(post.acfEvents.date).format("D.M.Y")} |{" "}
                {days(localeID)[moment(post.acfEvents.date).day()]}{" "}
                {post.acfEvents?.time}
              </span>
            </p>
          </div>
          <div className="imgCon16">
            <GatsbyImage
              image={
                post?.featuredImage?.node?.localFile?.childrenImageSharp[0]
                  ?.gatsbyImageData
              }
              alt={post?.featuredImage?.node?.altText ?? "Ilustrácia"}
            />
          </div>
          <h1>{post.title}</h1>
          <article>{parse(post.content)}</article>
        </div>
      </div>
      <PartnersSlider className="gray" localeID={localeID} />
    </Layout>
  )
}

export default StoreDetailTemplate

export const pageQuery = graphql`
  query EventDetail($id: String!) {
    post: wpPost(id: { eq: $id }) {
      ...EventSliderData
      content
      translated {
        slug
      }
      slug
    }
  }
`
